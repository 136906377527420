import { $t } from '@/i18n';
import useValidate from '@/use/validate';
import { submitFinalStep } from '@/handlers/shared';

const {
  isEmail,
  isPassword,
  isWeakPhone,
} = useValidate();

export default () => ({
  id: 'student',

  fallbackFlowId: process.env.VUE_APP_DEFAULT_FLOW,

  layout: {
    path: 'layouts/DefaultLayout',
    props: {},
  },

  steps: [
    {
      id: 'home',
      routeName: null,
      component: {
        path: 'views/student/HomeView',
      },
      isPublic: true,
      headerTheme: 'blue',
      saveSessionOnSubmit: false,
      analyticsMetaFields: {
        eloquaFormName: $t('flows.student.steps.home.eloquaFormName'),
      },
      fields: [
        {
          name: 'email',
          value: '',
          defaultValue: '',
          placeholder: $t('flows.student.steps.home.studentEmailAddress'),
          validatorClientSide: ({ email }) => isEmail(email.value),
        },
        {
          name: 'eligible',
          value: false,
          defaultValue: false,
          validatorClientSide: ({ eligible }) => eligible.value === true,
        },
      ],
    },

    {
      id: 'confirm',
      routeName: 'confirm',
      component: {
        path: 'views/shared/ConfirmView',
      },
      isPublic: true,
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'code',
          value: [],
          defaultValue: [],
          isOptional: true,
          omitFromSession: true,
        },
      ],
    },

    {
      id: 'profile',
      routeName: 'profile',
      component: {
        path: 'views/shared/ProfileView',
      },
      isPublic: false,
      stepperLabel: $t('flows.shared.steps.profile.stepperLabel'),
      saveSessionOnSubmit: true,
      fields: [
        {
          name: 'firstName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.firstName'),
        },
        {
          name: 'lastName',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.lastName'),
        },
        {
          name: 'phone',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.phone'),
          validatorClientSide: ({ phone }) => isWeakPhone(phone.value),
        },
        {
          name: 'password',
          value: '',
          defaultValue: '',
          placeholder: $t('shared.password'),
          omitFromSession: true,
          validatorClientSide: ({ password }) => isPassword(password.value),
        },
        {
          name: 'country',
          value: '',
          defaultValue: '',
          placeholder: $t('flows.student.steps.profile.country'),
          isTrackedByAnalytics: true,
        },
        {
          name: 'agreeToTerms',
          value: false,
          defaultValue: false,
          validatorClientSide: ({ agreeToTerms }) => agreeToTerms.value === true,
        },
      ],
    },

    {
      id: 'enrollment',
      routeName: 'enrollment',
      component: {
        path: 'views/student/EnrollmentView',
      },
      isPublic: false,
      stepperLabel: $t('flows.student.steps.enrollment.stepperLabel'),
      saveSessionOnSubmit: true,
      submitHandler: submitFinalStep,
      fields: [
        {
          name: 'program',
          value: '',
          defaultValue: '',
          placeholder: $t('flows.student.steps.enrollment.studyProgramPlaceholder'),
        },
        {
          name: 'graduationMonth',
          value: '',
          defaultValue: '',
          placeholder: $t('flows.student.steps.enrollment.month'),
        },
        {
          name: 'graduationYear',
          value: '',
          defaultValue: '',
          placeholder: $t('flows.student.steps.enrollment.year'),
        },
      ],
    },
  ],
});
